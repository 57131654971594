:root {
  --header-height: 80px;
  --footer-desktop-height: 80px;
}

.app {
}

/* Header */

header {
  display: flex;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  height: var(--header-height);
  gap: 20px;
  padding: 0 20px;
  background: #fff;
  z-index: 100;
}

@media (prefers-color-scheme: dark) {
  header {
    background: #000;
  }
}

header[class*='is-box-shadowed'] {
  box-shadow: rgb(149 157 165 / 10%) 0 8px 24px;
}

@media (prefers-color-scheme: dark) {
  header[class*='is-box-shadowed'] {
    box-shadow: none;
    border-bottom: 1px solid var(--black-color-1000)
  }
}

.header-link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: inherit !important;
}

.header-link > img {
  width: auto;
  height: 40px;
}

.header-link > img.logo-white {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .header-link > img.logo-white {
    display: block;
  }

  .header-link > img.logo-dark {
    display: none;
  }
}

.header-link > h1 {
  margin-top: 0 !important;
  font-weight: 900;
  font-size: 18pt
}

header > nav {
  display: flex;
  justify-content: center;
  flex: 1
}

@media (max-width: 720px) {
  header > nav {
    justify-content: flex-end;
  }
}

@media (max-width: 680px) {
  header > nav {
    display: none;
  }
}

header > nav > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 14pt;
  color: inherit !important;
}

header > nav > a::after {
  content: '';
  position: absolute;
  height: 3px;
  border-radius: 100px;
  background: #000;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition: opacity .21s, width .32s;

  width: 0;
  opacity: 0;
}

@media (prefers-color-scheme: dark) {
  header > nav > a::after {
    background: #fff;
  }
}

header > nav > a:hover::after {
  width: 100%;
  opacity: 1;
}

header > .action-button {

}

@media (max-width: 720px) {
  header > .action-button {
    display: none;
  }
}

header > .menu-button {
  display: none;
  margin-left: auto;
}

@media (max-width: 680px) {
  header > .menu-button {
    display: block;
  }
}

header > .menu-button > svg {
  width: 38px
}

/* <main> */

main {
  min-height: calc(100vh - var(--header-height) - var(--footer-desktop-height));
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
}

main > [class*='-block'] {
  padding: 100px 0;
  scroll-margin-top: calc(var(--header-height) / 2);
}

@media (max-width: 680px) {
  main > [class*='-block'] {
    padding: 50px 0
  }
}

main > [class*='-block'] > h1 {
  text-align: center;
  font-size: 36pt
}

@media (max-width: 520px) {
  main > [class*='-block'] > h1 {
    font-size: 22pt
  }
}

/* Footer */

footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  background: #000;
  color: var(--black-color-30);
  padding: 20px;
  min-height: var(--footer-desktop-height)
}

@media (max-width: 680px) {
  footer {
    flex-direction: column-reverse;
    align-items: center;
    font-size: 11pt;
    text-align: center;
  }
}

footer a {
  color: inherit
}

footer a:hover {
  color: #fff
}

.footer-links {
  display: flex;
}

.footer-links > a {
  display: block;
  padding: 10px 15px;
  transition: .21s;
}

.footer-links > a:hover {
  transform: scale(1.1)
}

.footer-links > a > svg {
  width: 24px;
  fill: #fff;
}

/* Mobile aside */

.mobile-aside {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 101;
  background: #fff;
  box-shadow: rgb(0 0 0 / 16%) 0 10px 36px 0, rgb(0 0 0 / 6%) 0 0 0 1px;
  transition: .32s;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 25px 25px 0 0;
}

@media (prefers-color-scheme: dark) {
  .mobile-aside {
    background: #000;
    box-shadow: none;
    border-left: 1px solid var(--black-color-1000)
  }
}

.mobile-aside:not([class*='is-shown']) {
  transform: translateY(100%);
  visibility: hidden;
}

.mobile-aside > nav > a {
  display: block;
  color: #000;
  font-weight: bold;
  padding: 15px 0;
  text-align: center;
  font-size: 14pt
}

@media (prefers-color-scheme: dark) {
  .mobile-aside > nav > a {
    color: #fff
  }
}

.mobile-aside > button {
  margin-top: 50px;
}

/* Aside darker */
.aside-darker {
  position: fixed;
  background: rgb(0 0 0 / 70%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  backdrop-filter: blur(10px);
  visibility: visible;
  transition: .32s;
}

.aside-darker:not([class*='is-shown']) {
  opacity: 0;
  backdrop-filter: blur(0);
  visibility: hidden;
}
