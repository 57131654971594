.about-block {
  background: #fafafa;
  padding: 30px 50px !important;
  scroll-margin-top: calc(var(--header-height) * 2) !important;
  border-radius: 25px;
}

@media (max-width: 1080px) {
  .about-block {
    margin: 0 -20px;
    border-radius: 0;
    scroll-margin-top: var(--header-height) !important;
  }
}

@media (prefers-color-scheme: dark) {
  .about-block {
    background: var(--black-color-1000);
  }
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin-top: 50px;
}

@media (max-width: 1080px) {
  .features {
    gap: 30px 20px
  }
}

@media (max-width: 840px) {
  .features {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }
}

@media (max-width: 580px) {
  .features {
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
  }
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.feature-icon {
  display: flex;
  width: 120px;
  height: 120px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid var(--black-color-10)
}

@media (max-width: 1080px) {
  .feature-icon {
    width: 100px;
    height: 100px
  }
}

@media (max-width: 920px) {
  .feature-icon {
    width: 80px;
    height: 80px
  }
}

@media (max-width: 580px) {
  .feature-icon {
    width: 120px;
    height: 120px
  }
}

.feature-icon > svg {
  width: 50%;
  margin: auto;
}

.feature-title {
  margin-top: 20px;
  font-size: 18pt;
  font-weight: bold;
}

.feature-description {
  margin-top: 10px;
  font-style: italic;
}

@media (max-width: 1080px) {
  .feature-description {
    font-size: 11pt
  }
}

@media (max-width: 580px) {
  .feature-description {
    font-size: 12pt
  }
}
