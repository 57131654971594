/* Data rows */

.data-rows:not(:first-child) {
  margin-top: 20px;
}

.data-rows > .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px 10px;
  padding: 15px 0;
  border-bottom: 1px dashed var(--black-color-30);
  user-select: text;
}

@media (prefers-color-scheme: dark) {
  .data-rows > .row {
    border-bottom-color: var(--black-color-900);
  }
}

.data-rows > .row > div {
  word-break: break-word;
}

.data-rows > .row > div:first-child {
  font-weight: bold;
}

/* Loader container */

.loader-container {
  display: flex;
  justify-content: center;
  padding: 50px;
}

/* Contacts */
.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

@media (max-width: 980px) {
  .contacts {
    margin-top: 20px
  }
}

.contacts > a {
  display: flex;
  align-items: center;
  gap: 0 15px;
  color: #000;
  font-size: 14pt;
}

@media (prefers-color-scheme: dark) {
  .contacts > a {
    color: #fff
  }
}

@media (max-width: 980px) {
  .contacts > a {
    font-size: 12pt;
    gap: 0 10px
  }
}

@media (max-width: 780px) {
  .contacts > a {
    justify-content: center;
    margin-top: 30px;
    font-size: 14pt;
    gap: 0 15px;
  }
}

.contacts > a:not(:first-child) {
  margin-top: 20px;
}

.contacts > a > svg {
  width: 35px
}

@media (prefers-color-scheme: dark) {
  .contacts > a > svg {
    fill: #fff
  }
}

@media (max-width: 980px) {
  .contacts > a > svg {
    width: 25px
  }
}
