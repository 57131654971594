.modal-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgb(0 0 0 / 30%);
  z-index: 900;
  backdrop-filter: blur(20px);
  padding: 100px 0;
  overflow: auto;
  overscroll-behavior: none;
}

@media (max-width: 680px) {
  .modal-window {
    padding: 100px 0 0;
    backdrop-filter: blur(10px);
  }
}

.wrapper {
  margin: auto;
  width: 100%;
  max-width: 680px;
  background: #fff;
  box-shadow: rgb(0 0 0 / 25%) 0 25px 50px -12px;
  border-radius: 15px;
  overflow: hidden;
}

@media (prefers-color-scheme: dark) {
  .wrapper {
    background: var(--black-color-900);
  }
}

@media (max-width: 720px) {
  .wrapper {
    max-width: 480px;
  }
}

@media (max-width: 480px) {
  .wrapper {
    margin: auto 0 0;
    flex: 1;
    border-radius: 25px 25px 0 0;
    overflow: unset;
  }
}

.header {
  position: sticky;
  top: -100px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  background: var(--accent-color-100);
  border-radius: 15px 15px 0 0;
  padding: 15px 20px;
  color: #fff;
  font-weight: bolder;
  font-size: 14pt;
}

@media (max-width: 480px) {
  .header {
    background: #fff;
    color: #000;
    justify-content: center;
    border-bottom: 1px solid var(--black-color-30);
    border-radius: 25px 25px 0 0;
  }
}

@media (max-width: 480px) and (prefers-color-scheme: dark) {
  .header {
    background: var(--black-color-900);
    color: #fff;
    border-bottom-color: var(--black-color-800);
  }
}

@media (max-width: 480px) {
  .header > button {
    display: none;
  }
}

.header > button > svg {
  width: 24px;
  fill: #fff !important;
}

.header.is-no-closable {
  justify-content: center;
}

.header-title {
  font-weight: bold;
  font-size: 14pt;
}

.contents {
  padding: 20px;
}

@media (prefers-color-scheme: dark) {
  .contents hr {
    background: var(--black-color-800);
  }

  .contents [class='data-rows'] > [class='row'] {
    border-bottom-color: var(--black-color-500);
  }
}
